<template>
    <div class="page">

        <div class="page__header">
            <div class="page__info">
                <div v-if="category != null" class="page__title">{{ category.displayName }}</div>
                <div class="page__desc">Список товаров в категории</div>
            </div>
        </div>

        <div class="page__content">
            <div class="table-responsive">
                <table class="table table-striped">
                    <tr>
                        <th>Название</th>
                    </tr>
                    <tr v-for="product in products"
                        v-bind:key="product.id">
                        <td>{{ product.regulatedProductName }}</td>
                    </tr>
                </table>
            </div>
        </div>

    </div>
</template>


<script>
    import { mapActions } from 'vuex'

    export default {
        name: 'MetroCategoriesProducts',
        computed: {
            products() {
                return this.$store.state.metro.metroProducts
            },
            category() {
                return this.$store.state.metro.metroCategory
            },
        },
        methods: {
            ...mapActions([
                'metroCategoriesProducts',
            ]),
            async getProducts() {
                await this.metroCategoriesProducts({
                    id: this.$route.params.id,
                })
            },
        },
        created() {
            this.getProducts();
        }
    }
</script>

<style lang="scss" scoped>
    .btn {
        margin-right: 10px;
    }
</style>